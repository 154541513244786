import App from "app"
import Element from "element"

import { create, find, delay, on, off } from "helper"

export default class StartButton extends Element
{
    constructor()
    {
        super("#start-button")
    }
    
    async start()
    {
        await delay(300)
        
        const button = find("#start-button-button")
        const info = find("#start-button-info")
        
        button.playAnimation("zoom-fadein", { duration: 300 })
        info.playAnimation("fadein", { duration: 500 })
        await delay(200)
        
        const onClick = async () =>
        {
            off("keydown", onClick)
            
            info.playAnimation("fadeout", { duration: 400 })
            
            button.addClass("active")
                  .off("click", onClick)
                  .playAnimation("zoom-fadeout", { duration: 600 })
            await delay(100)
            
            button.removeClass("active")
            await delay(100)
            
            await App.instance.start()
            await delay(400)
            
            this.parent = null
        }
        
        on("keydown", onClick)
        button.on("click", onClick)
    }
}