import Track from "track"

import { get } from "helper"

export default class Playlist
{
    id = 0
    name
    tracks = []
    
    static async getAllFromApi()
    {
        const playlists = await get(`/api/playlists`).then(x => x.json())
        
        return this.fromArray(playlists)
    }
    
    static fromArray(playlists)
    {
        const _items = []
        for (const playlist of playlists)
            _items.add(this.fromObject(playlist))
        return _items
    }
    
    static fromObject(item)
    {
        const _item = new Playlist
        _item.id = item.id
        _item.name = item.name
        _item.tracks = Track.fromArray(item.tracks)
        return _item
    }
}