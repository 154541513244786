import Element from "element"

import { extend, isUndefined, isBool, isNumber, delay, until, nextFrame } from "helper"

extend([ EventTarget.prototype, Node.prototype, Element.prototype ],
{
    original:
    {
        get: function()
        {
            if (this instanceof Element)
                return this.node
            
            return this
        }
    },
    find: function()
    {
        return this.original.querySelector(...arguments)
    },
    findAll: function()
    {
        return this.original.querySelectorAll(...arguments)
    },
    addClass: function()
    {
        this.original.classList.add(...arguments)
        return this
    },
    removeClass: function()
    {
        this.original.classList.remove(...arguments)
        return this
    },
    hasClass: function()
    {
        return this.original.classList.contains(...arguments)
    },
    toggleClass: function()
    {
        if (this.hasClass(...arguments))
        {
            this.removeClass(...arguments)
            return false
        }
        
        this.addClass(...arguments)
        return true
    },
    setClass: function(className, active)
    {
        if (active)
            return this.addClass(className)
        
        return this.removeClass(className)
    },
    enable: function()
    {
        this.original.removeAttribute("disabled")
        return this
    },
    disable: function()
    {
        this.original.setAttribute("disabled", "disabled")
        return this
    },
    isHidden: function()
    {
        return this.hasClass("hidden")
    },
    isVisible: function()
    {
        return !this.isHidden()
    },
    setData: function(key, value)
    {
        this.original.dataset[key] = value
        return this
    },
    setParent: function(value)
    {
        const original = this.original
        const parent = original.parentNode
        if (parent)
            parent.original.removeChild(original)

        if (!value)
            return

        value.original.appendChild(original)
        return this
    },
    appendTo: function(value)
    {
        return this.setParent(value)
    },
    insertBefore: function()
    {
        return this.original.insertBefore(...arguments)
    },
    style:
    {
        get: function()
        {
            return this.original.style
        }
    },
    setHtml: function(value)
    {
        if (value instanceof Node || value instanceof Element)
        {
            this.original.innerHTML = ""
            value.setParent(this)
            return this
        }
        
        this.original.innerHTML = value
        return this
    },
    clear: function()
    {
        this.setHtml("")
    },
    parent:
    {
        get: function()
        {
            return this.original.parentNode
        },
        set: function(value)
        {
            this.setParent(value)
        }
    },
    html:
    {
        get: function()
        {
            return this.original.innerHTML
        },
        set: function(value)
        {
            this.setHtml(value)
        }
    },
    show: async function(options)
    {
        if (isBool(options))
        {
            options = { visible: options }
            if (arguments.length >= 2)
                options.duration = arguments[1]
        }
        else if (isNumber(options))
        {
            options = {
                visible: true,
                duration: options
            }
        }
        
        const original = this.original
        
        original.queue ??= 0
        original.business ??= 0
        
        if (original.queue >= 2)
            return
        
        original.queue++
        await until(() => original.business == 0)
        original.queue--
        
        original.business++
        
        options ??= { }
        
        if (isUndefined(options.visible))
            options.visible = this.isHidden()
        
        if (options.duration)
        {
            this.style.transitionDuration = `${options.duration}ms`
            await nextFrame()
        }
        
        this.setClass("hidden", !options.visible)
        
        if (options.duration)
        {
            await delay(options.duration)
            this.style.transitionDuration = null
        }
        
        original.business--
        
        return this
    },
    hide: async function(duration)
    {
        return await this.show({ visible: false, duration: duration })
    },
    scrollDown: function()
    {
        const original = this.original
        original.scrollTo({ top: original.scrollHeight, behavior: "smooth" })
        return this
    },
    hasFocus: function()
    {
        return this.original == document.activeElement
    },
    bind: function(obj, key)
    {
        const original = this.original
        original.value = obj[key]
        if (original._bindEvent)
            this.off("input", original._bindEvent)
        original._bindEvent = () => obj[key] = original.value
        this.on("input", original._bindEvent)
    },
    playAnimation: async function(name, options)
    {
        options ??= { }
        
        const duration = options.duration ?? 1000
        const curve = options.curve ?? "ease"
        const reset = options.reset ?? false
        
        this.style.animation = name
        
        this.style.animationDuration = `${duration}ms`
        this.style.animationTimingFunction = curve
        
        if (!reset)
        this.style.animationFillMode = "forwards"
        
        await delay(duration)
        return this
    }
})