import Element from "element"
import Formula from "formula"

import { delay } from "helper"

export default class Commandline extends Element
{
    #input
    #placeholder
    
    #onInput = []
    #onSubmit = []
    
    constructor()
    {
        super("div#commandline")
        
        this.on("keydown", e => this.onKeyDown(e))
        
        this.#input = this.find("input")
        this.#placeholder = this.parent.find(".placeholder")
    }
    
    get value()
    {
        return this.#input.value
    }
    
    set value(value)
    {
        this.#input.value = value
        this.refresh()
    }
    
    async activate()
    {
        this.#input.removeAttribute("disabled")
        this.#input.focus()
        await this.refresh()
    }
    
    deactivate()
    {
        this.#input.setAttribute("disabled", "disabled")
    }
    
    set onInput(value)
    {
        this.#onInput.add(value)
    }
    
    set onSubmit(value)
    {
        this.#onSubmit.add(value)
    }
    
    async onKeyDown(e)
    {
        if (e.arrowUp || e.arrowDown)
        {
            e.preventDefault()
            return
        }
        
        if (e.enter)
        {
            e.preventDefault()
            Formula.applyMathSymbols(this.#input)
            this.#onSubmit.forEach(async x => await x.call(this, e))
            return
        }
        
        await this.refresh()
        
        for (const onInput of this.#onInput)
            await onInput.call(this, e)
    }
    
    async refresh()
    {
        await delay()
        await this.refreshPlaceholder()
        Formula.applyMathSymbols(this.#input)
    }
    
    async refreshPlaceholder()
    {
        if (this.value.length == 0)
        {
            this.#placeholder.innerHTML = `&gt; Type in a formula or "help"`
            this.#placeholder.removeClass("filled")
            return
        }
    
        this.#placeholder.innerHTML = "&gt;"
        this.#placeholder.addClass("filled")
    }
}