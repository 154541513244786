import Element from "element"
import Entry from "entry"
import EntryForm from "entry-form"
import Formula from "formula"

import { create } from "helper"

export default class Content extends Element
{
    entryForm = new EntryForm
    
    constructor()
    {
        super("#content")
        
        this.entryForm.onDelete = entry =>
        {
            this.entries.remove(entry)
        }
    }
    
    async refresh()
    {
        this.entries = await Entry.getAllFromApi()
        
        this.html = ""
        
        this.previewResultSeparator = create("div")
            .addClass("flex-1")
            .appendTo(this)
        
        this.previewResult = create("div")
            .addClass("no-hover")
            .appendTo(this)
        
        for (const entry of this.entries)
            this.addNode(entry)
    }
    
    addNode(entry)
    {
        const node = entry.result.node
        node.id = entry.nodeId
        node.on("click", () =>
        {
            if (entry.result.type != "emulator-game")
                this.entryForm.open(entry)
        })
        this.insertBefore(node, this.previewResultSeparator)
    }
    
    async setPreviewResult(expression)
    {
        const formula = new Formula(expression)
        const result = await formula.toResult()
        this.previewResult.html = result.node
    }
}