import App from "app"
import Entry from "entry"
import Playlist from "playlist"
import Result from "result"

import {
    isBool, isFunction, isDefined,
    generate, guid,
    get, post, postForm, del
} from "helper"

import { create, all } from "mathjs"

export default class Formula
{
    static #mathjs = null
    
    static rules = [
        {
            pattern: /^#\s*(.*)/,
            callback: function(data)
            {
                return new Result(this, "comment", data.matches[1], { persistent: true })
            }
        },
        {
            //pattern: /^play\s+zelda$/,
            pattern: /^play\s+sonic$/,
            preventMathSymbols: true,
            callback: function(data)
            {
                if (!data.entry)
                    return new Result(this, "emulator-game")
                
                return new Result(this, "emulator-game", data.entry.nodeId)
            }
        },
        {
            pattern: /^help$/,
            preventMathSymbols: true,
            callback: function(data)
            {
                return new Result(this, "help")
            }
        },
        {
            pattern: /^track(?:(|s))(?:(|\s|\s+.+))$/,
            preventMathSymbols: true,
            callback: async function(data)
            {
                const tracks = App.instance.getTracks(data.matches[2])
                return new Result(this, "tracks", tracks)
            }
        },
        {
            pattern: /^add\strack(?:(|\s|\s+.+))$/,
            preventMathSymbols: true,
            callback: async function(data)
            {
                const tracks = App.instance.getTracks(data.matches[1])
                
                if (data.entry)
                {
                    if (tracks.length == 1)
                    {
                        const url = `/api/player/track?${App.instance.queryString}`
                        const ok = await postForm(url, { id: tracks[0].id }).then(x => x.ok)
                        return new Result(this, ok)
                    }
                    return new Result(this, false)
                }
                
                return new Result(this, "tracks", tracks)
            }
        },
        {
            pattern: /^play\s(.+)$/,
            preventMathSymbols: true,
            callback: async function(data)
            {
                const tracks = App.instance.getTracks(data.matches[1])
                
                if (data.entry)
                {
                    if (tracks.length == 1)
                    {
                        const url = `/api/player/track?${App.instance.queryString}&play`
                        const ok = await postForm(url, { id: tracks[0].id }).then(x => x.ok)
                        return new Result(this, ok)
                    }
                    return new Result(this, false)
                }
                
                return new Result(this, "tracks", tracks)
            }
        },
        {
            pattern: /^(?:(del|delete|rm|remove))\s+track(?:(|\s|\s+.+))$/,
            preventMathSymbols: true,
            callback: async function(data)
            {
                const tracks = App.instance.getTracks(data.matches[2])
                
                if (data.entry)
                {
                    if (tracks.length == 1)
                    {
                        const url = `/api/player/track/${tracks[0].id}?${App.instance.queryString}`
                        const ok = await del(url).then(x => x.ok)
                        return new Result(this, ok)
                    }
                    return new Result(this, false)
                }
                
                return new Result(this, "tracks", tracks)
            }
        },
        {
            pattern: /^(?:(add|create|new))\s+playlist$/,
            preventMathSymbols: true,
            callback: async function(data)
            {
                if (data.entry)
                {
                    const ok = await post("/api/playlists").then(x => x.ok)
                    if (ok)
                    {
                        await App.instance.updatePlaylists()
                        return new Result(this, true)
                    }
                    return new Result(this, false)
                }
                return new Result(this, true)
            }
        },
        {
            pattern: /^(?:(del|delete|rm|remove))\s+playlist(?:(|\s|\s+.+))$/,
            preventMathSymbols: true,
            callback: async function(data)
            {
                const playlists = App.instance.getPlaylists(data.matches[2])
                
                if (data.entry)
                {
                    if (playlists.length == 1)
                    {
                        const url = `/api/playlists/${playlists[0].id}`
                        const ok = await del(url).then(x => x.ok)
                        if (ok)
                        {
                            await App.instance.updatePlaylists()
                            return new Result(this, true)
                        }
                    }
                    return new Result(this, false)
                }
                return new Result(this, "playlists", playlists)
            }
        },
        {
            pattern: /^playlist(?:(|s))(?:(|\s|\s+.+))$/,
            preventMathSymbols: true,
            callback: async function(data)
            {
                const playlists = App.instance.getPlaylists(data.matches[2])
                
                if (data.entry)
                {
                    if (playlists.length == 1)
                    {
                        const playlist = await get(`/api/playlists/${playlists[0].id}`).then(x => x.json())
                        if (playlist)
                        {
                            App.instance.playlistId = playlist.id
                            return new Result(this, true)
                        }
                        return new Result(this, false)
                    }
                }
                
                return new Result(this, "playlists", playlists)
            }
        },
        {
            pattern: /.+/,
            callback: function(data)
            {
                try
                {
                    const expression = data.matches[0]
                        .replace("==", "=")
                        .replace("==", "=")
                        .replace("=", "==")
                        .replace(">==", ">=")
                        .replace("<==", "<=")
                        .replace("<>", "!=")
                    
                    let value = Formula.#evaluate(expression)
                    
                    if (isFunction(value))
                    {
                        return new Result(this, "warning", `${expression} is a function`)
                    }
                    
                    if (isBool(value))
                    {
                        return new Result(this, "comperation", value, { persistent: true })
                    }
                    
                    return new Result(this, "solution", value, { persistent: true })
                }
                catch (error)
                {
                    let value = error.toString()
                    
                    if (value.startsWith("Error:"))
                        value = value.substring(6).trim()
                    
                    return new Result(this, "error", value)
                }
            }
        }
    ]
    
    static get mathjs()
    {
        if (!Formula.#mathjs)
        {
            Formula.#mathjs = create(all, {
                number: 'BigNumber',
                precision: 64
            })
            
            Formula.#mathjs.import({
                n: '1e+63',
                LEET: "1337",
                TheAnswerToLifeTheUniverseAndEverything: 42,
                ln: Formula.#mathjs.log,
                log: (base, x) =>
                {
                    return math.bignumber(math.log10(x) / math.log10(base))
                },
                time: () =>
                {
                    return new Date().getTime()
                },
                generate: length =>
                {
                    return generate(length)
                },
                guid: () =>
                {
                    return guid()
                },
                spellGerman: text =>
                {
                    const characters = {
                        A: "Anton", B: "Berta", C: "Cäsar", D: "Dora", E: "Emil", F: "Friedrich",
                        G: "Gustav", H: "Heinrich", I: "Ida", J: "Julius", K: "Konrad", L: "Ludwig",
                        M: "Martha", N: "Nordpol", O: "Otto", P: "Paula", Q: "Qualle", R: "Richard",
                        S: "Siegfried", T: "Theodor", U: "Ulrich", V: "Viktor", W: "Wilhelm", X: "Xaver",
                        Y: "Ypsilon", Z: "Zebra"
                    }
                    const result = []
                    for (const c of text.toUpperCase())
                        result.add(characters[c] || c)
                    return result.join(' ')
                },
                spellEnglish: text =>
                {
                    const characters = {
                        A: "Alfa", B: "Bravo", C: "Charlie", D: "Delta", E: "Echo", F: "Foxtrot",
                        G: "Golf", H: "Hotel", I: "India", J: "Juliett", K: "Kilo", L: "Lima",
                        M: "Mike", N: "November", O: "Oscar", P: "Papa", Q: "Quebec", R: "Romeo",
                        S: "Sierra", T: "Tango", U: "Uniform", V: "Victor", W: "Whiskey", X: "X-ray",
                        Y: "Yankee", Z: "Zulu"
                    }
                    const result = []
                    for (const c of text.toUpperCase())
                        result.add(characters[c] || c)
                    return result.join(' ')
                },
                tobase64: text => btoa(text),
                frombase64: text => atob(text),
                clock: () =>
                {
                    const time = new Date().getTime()
                    let hh = math.ceil(time % (24 * 60 * 60 * 1000) / (60 * 60 * 1000)).toString()
                    let mm = math.floor(time % (60 * 60 * 1000) / (60 * 1000)).toString()
                    let ss = math.floor(time % (60 * 1000) / 1000).toString()
                    if (hh.length == 1) hh = "0" + hh
                    if (mm.length == 1) mm = "0" + mm
                    if (ss.length == 1) ss = "0" + ss
                    return hh + ":" + mm + ":" + ss
                },
            }, { override: true })
        }
        
        return Formula.#mathjs
    }
    
    static #evaluate(expression)
    {
        if (expression.indexOf('"') != -1)
        {
            try
            {
                const result = this.#evaluateJS(expression)
                if (isDefined(result))
                    return result
            }
            catch
            {
                // nothing to do
            }
        }
        
        return this.mathjs.evaluate(expression)
    }
    
    static #evaluateJS(expression)
    {
        const ab = expression.split("==")
        
        if (ab.length == 2)
        {
            const a = ab[0].trim()
            const b = ab[1].trim()
            return this.#compareStrings(a, b)
        }
        
        const result = this.#solveString(expression)
        return result.toJson()
    }
    
    static #compareStrings(a, b)
    {
        const herolist = App.instance.herolist
        a = eval(a)
        b = eval(b)
        return herolist.compare(a, b)
            ?? a == b
    }
    
    static #solveString(expression)
    {
        const herolist = App.instance.herolist
        const solution = eval(expression)
        return herolist.resolve(solution)
            ?? solution
    }
    
    static applyMathSymbols(input)
    {
        let value = input.value
        
        if (value.indexOf('"') != -1)
            return
        
        for (const rule of Formula.rules)
            if (rule.preventMathSymbols)
                if (rule.pattern.test(value))
                    return
        
        const length = value.length
        const list = {
            "pi": 'π',
            "phi": 'φ',
            "tau": 'τ',
            "τsi": 'tausi',
            "sqrt(": '√(',
            "sqrt": '√(',
            "*": '×',
            "/": '÷',
            '÷s': "/s",
            '÷h': "/h",
            "^": '⇧',
            "⇧0": '⁰',
            "⇧1": '¹',
            "⇧2": '²',
            "⇧3": '³',
            "⇧4": '⁴',
            "⇧5": '⁵',
            "⇧6": '⁶',
            "⇧7": '⁷',
            "⇧8": '⁸',
            "⇧9": '⁹'
        }
        
        for (const i in list)
            value = value.split(i).join(list[i])
        
        if (input.value == value)
            return
        
        const offset = length - value.length
        const index = input.selectionStart - offset
        input.value = value
        input.setSelectionRange(index, index)
    }
    
    constructor(value)
    {
        this.value = value ? value.trim() : ""
    }
    
    async toEntry()
    {
        const entry = new Entry
        entry.expression = this.value
        entry.result = await this.toResult({ entry: entry })
        return entry
    }
    
    async toResult(options)
    {
        options ??= { }
        
        let value = this.value
        
        const list = {
            '⁰': "^0",
            '¹': "^1",
            '²': "^2",
            '³': "^3",
            '⁴': "^4",
            '⁵': "^5",
            '⁶': "^6",
            '⁷': "^7",
            '⁸': "^8",
            '⁹': "^9",
            'π': "pi",
            'φ': "phi",
            '√': "sqrt",
            'τ': "tau",
            '×': "*",
            '÷': "/",
            '⇧': "^"
        }
        
        for (const i in list)
            value = value.split(i).join(list[i])
        
        let result = null
        
        for (const rule of Formula.rules)
        {
            let matches
            if (isFunction(rule.pattern))
                matches = rule.pattern()
            else
                matches = value.match(rule.pattern)
            
            if (!matches)
                continue
            
            result = await rule.callback.call(this, {
                matches: matches,
                entry: options.entry
            })
            break
        }
        
        result ||= new Result(this, "empty")
        
        return result
    }
}