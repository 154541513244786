import "./extensions/event-target"
import "./extensions/node"

import App from "./app"

export function extend(prototypes, definitions)
{
    for (const key in definitions)
    {
        const value = definitions[key]
        for (const prototype of prototypes)
        {
            if (prototype.hasOwnProperty(key))
                continue
            
            let descriptor = value
            if (isFunction(descriptor))
                descriptor = { value: descriptor }
            
            descriptor.enumerable = false
            Object.defineProperty(prototype, key, descriptor)
        }
    }
}

export function on()
{
    window.on(...arguments)
}

export function off()
{
    window.off(...arguments)
}

export function create()
{
    return document.createElement(...arguments)
}

export function find()
{
    return document.find(...arguments)
}

export function findAll()
{
    return document.findAll(...arguments)
}

export function delay()
{
    return new Promise(resolve =>
    {
        setTimeout(resolve, ...arguments)
    })
}

export function nextFrame()
{
    return new Promise(resolve =>
    {
        window.requestAnimationFrame(resolve)
    })
}

export function until(predicate)
{
    return new Promise(async resolve =>
    {
        while (!predicate())
            await nextFrame()
        resolve()
    })
}

export function isNull(obj)
{
    return obj === null
}

export function isUndefined(obj)
{
    return obj === undefined
}

export function isDefined(obj)
{
    return !isUndefined(obj)
}

export function isBool(obj)
{
    return typeof obj == "boolean"
}

export function isNumber(obj)
{
    return typeof obj == "number"
}

export function isString(obj)
{
    return typeof obj == "string"
}

export function isFunction(obj)
{
    return typeof obj == "function"
}

export function isArray(obj)
{
    return typeof obj == "array"
}

export function isObject(obj)
{
    return typeof obj == "object"
}

export function now()
{
    return new Date()
}

export function get(url)
{
    return fetch(url)
}

export function post(url, data)
{
    const options = { method: "post" }
    
    if (isDefined(data) && !isNull(data))
    {
        options.headers = { 'Content-Type': 'application/json' }
        options.body = data.toJson()
    }
    
    return fetch(url, options)
}

export function postForm(url, data)
{
    const formData = new FormData
    
    for (const key in data)
        formData.append(key, data[key])
        
    return fetch(url, {
        method: "post",
        body: formData
    })
}

export function put(url, data)
{
    return fetch(url, {
        method: "put",
        headers: { 'Content-Type': 'application/json' },
        body: data.toJson()
    })
}

export function del(url)
{
    return fetch(url, { method: "delete" })
}

export function clamp(value, min, max)
{
    return Math.min(Math.max(min, value), max)
}

export function generate(length)
{
    length ||= 24
    const chars = "ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz123456789"
    let result = ""
    for (let i = 0; i < length; i++)
        result += chars.charAt(Math.floor(Math.random() * chars.length))
    return result;
}

export function guid()
{
    const result = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c =>
    {
        const r = Math.random() * 16 | 0
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return result
}