import App from "app"
import Formula from "formula"
import Result from "result"

import { get, uniqueId } from "helper"

export default class Entry
{
    id = 0
    timestamp
    modificationTimestamp
    expression = null
    result = new Result(null, "unknown")
    
    #nodeId = `entry-${App.instance.generateUniqueId()}`
    
    get nodeId()
    {
        return this.#nodeId
    }
    
    static async getAllFromApi()
    {
        const items = await get("/api/entries").then(x => x.json())
        return this.fromArray(items)
    }
    
    static fromArray(items)
    {
        const _items = []
        for (const item of items)
            _items.add(this.fromObject(item))
        return _items
    }
    
    static fromObject(item)
    {
        const result = new Result
        Object.assign(result, item.result)
        
        const _item = new Entry
        Object.assign(_item, item)
        
        _item.result = result
        _item.result.formula = new Formula(_item.expression)
        
        return _item
    }
}