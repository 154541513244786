export default class HeroList
{
	#items
	
	constructor()
	{
		this.#items = HeroList.#defaultItems
	}
	
	compare(a, b)
	{
		const _a = [ a, ...this.resolve(a) ]
		const _b = [ b, ...this.resolve(b) ]
		
		if (_a.length <= 1 || _b.length <= 1)
			return false
		
		for (const __a of _a)
			for (const __b of _b)
				if (__a == __b)
					return true
		
		return false
	}
	
	resolve(input)
	{
		const result = []
		
		for (const i in this.#items)
		{
			const item = this.#items[i]
			
			if (input == item[1])
				result.push(item[0])
			
			if (input == item[0])
				result.push(item[1])
		}
		
		if (result.length == 0)
			return undefined
		
		return result
	}
	
	static #defaultItems = [
		
		[ "Captain Lea Corbin", "4-D" ],
		[ "Steve Austin", "6 Million Dollar Man" ],
		[ "Arnold Etchison", "Abattoir" ],
		[ "Abel", "Abel" ],
		[ "Axel Asher Amalgam Comics", "Access" ],
		[ "Female Android", "Ace" ],
		[ "Male Android", "Ace" ],
		[ "Max Crandall", "Ahwehotah" ],
		[ "Larry Jordan", "Air Wave" ],
		[ "Helen Jordan", "Air Wave" ],
		[ "Harold Jordan", "Air Wave" ],
		[ "Jan Arrah", "Alchemist" ],
		[ "Tom Ludlow Hallaway", "Alias the Spider" ],
		[ "Marcus Aelius", "Alpha Centurion" ],
		[ "Will Everett", "Amazing Man" ],
		[ "Will Everett Junior", "Amazing Man" ],
		[ "Android", "Amazo" ],
		[ "Tex Thompson", "Americommando" ],
		[ "Bartholomew Gallows", "Americop" ],
		[ "Tike Alicar", "Anarchist" ],
		[ "Lonnie Machin", "Anarky" ],
		[ "Angelo Bend", "Angle Man" ],
		[ "Bernhard Baker", "Animal Man" ],
		[ "Victor Borkowski", "Anole" ],
		[ "Tula, Lorena Marquez", "Aquagirl" ],
		[ "Orin or Arthur Curry", "Aquaman" ],
		[ "Toni Monetti", "Argent" ],
		[ "Jack Keaton", "Armor" ],
		[ "Cissie King-Jones", "Arrowette" ],
		[ "Roy Harper", "Arsenal" ],
		[ "Jim Randall", "Atlas" ],
		[ "Al Rothstein", "Atom Smasher" ],
		[ "Gardner Grayle", "Atomic Knight" ],
		[ "Adam Mann", "Atomic Man" ],
		[ "Dr. Noah Talbot", "Atomic Sub" ],
		[ "William Burns", "Atomic Thunderbolt" ],
		[ "Jean Paul Valley", "Azrael" ],
		[ "Uno or Curt Falconer", "Aztek" ],
		[ "Greg Mattingly", "Backhand" ],
		[ "Marc Slayton", "Backlash" ],
		[ "Catherine Bell", "Bad Kitty" ],
		[ "Thomas John McCall", "Badrock" ],
		[ "Suzanne Melotti", "Bagabond" ],
		[ "Cassandra Lane", "Ballistic" ],
		[ "Sean Cassidy", "Banshee" ],
		[ "Barbara Gordon", "Batgirl" ],
		[ "Helena Bertinelli", "Batgirl" ],
		[ "Cassandra Cain", "Batgirl" ],
		[ "Barbara Wilson", "Batgirl" ],
		[ "Bruce Wayne", "Batman" ],
		[ "Jean Paul Valley", "Batman" ],
		[ "Dick Grayson", "Batman" ],
		[ "Terry McGinnis", "Batman" ],
		[ "Jackson King", "Battalion" ],
		[ "Unknown", "Battering Ram" ],
		[ "Kathy Kane", "Batwoman" ],
		[ "Henry P. McCoy", "Beast" ],
		[ "Garfield Logan", "Beast Boy" ],
		[ "Barda Free", "Big Barda" ],
		[ "Percy Pilbeam", "Big E" ],
		[ "Teth-Adam", "Black Adam" ],
		[ "Theo Adam", "Black Adam" ],
		[ "Dinah Lance", "Black Canary" ],
		[ "Richard Grey Jr.", "Black Condor" ],
		[ "Ryan Kendall", "Black Condor" ],
		[ "Jefferson Pierce", "Black Lightning" ],
		[ "Roman Sionis", "Black Mask" ],
		[ "Sgt. Willie Walker", "Black Racer" ],
		[ "Johnny LaMonica", "Black Spider" ],
		[ "Janos Prohaska", "Blackhawk" ],
		[ "Dan Garrett", "Blue Beetle" ],
		[ "Ted Kord", "Blue Beetle" ],
		[ "Jaime Reyes", "Blue Beetle" ],
		[ "Daniel Patrick Cassidy", "Blue Devil" ],
		[ "Jay Abrams", "Blue Jay" ],
		[ "Michael Jon Carter", "Booster Gold" ],
		[ "Henry King Sr", "Brain Wave" ],
		[ "Henry King Jr.", "Brain Wave" ],
		[ "Ben Turner", "Bronze Tiger" ],
		[ "Guillermo Barrera", "Brutale" ],
		[ "Susan Kent-Barr", "Bulletgirl" ],
		[ "Jim Barr", "Bulletman" ],
		[ "Karen Beecher-Duncan", "Bumblebee" ],
		[ "Ryuko Orsono", "Bushido" ],
		[ "Cain, Keeper of Mysteries", "Cain" ],
		[ "Steven Rogers", "Captain America" ],
		[ "Captain Allen Adam", "Captain Atom" ],
		[ "Brian Braddock", "Captain Britain" ],
		[ "Daniel Eaton", "Captain Canada" ],
		[ "Tom Evans", "Captain Canuck" ],
		[ "David Semple", "Captain Canuck (00s)" ],
		[ "Darren Oak", "Captain Canuck (90s)" ],
		[ "Adam Blake", "Captain Comet" ],
		[ "Flash Dale", "Captain Conquest" ],
		[ "Tom Townsend", "Captain Flag" ],
		[ "Keith Spencer", "Captain Flash" ],
		[ "Don Wright", "Captain Freedom" ],
		[ "Billy Batson", "Captain Marvel" ],
		[ "Freddy Freeman", "Captain Marvel Jr." ],
		[ "Lance Gallant", "Captain Triumph" ],
		[ "Carrie Kelley", "Catgirl" ],
		[ "Selina Kyle", "Catwoman" ],
		[ "Drury Walker", "Charaxes" ],
		[ "Niles Caulder", "Chief" ],
		[ "Katherine Manser", "Cinnamon" ],
		[ "Basil Karlo", "Clayface" ],
		[ "Matt Hagen", "Clayface" ],
		[ "Preston Payne", "Clayface" ],
		[ "Sondra Fuller", "Clayface" ],
		[ "Cassius Payne", "Clayface" ],
		[ "Han", "Collective Man" ],
		[ "Chang", "Collective Man" ],
		[ "Lin", "Collective Man" ],
		[ "Sun", "Collective Man" ],
		[ "Ho Tao-Yu", "Collective Man" ],
		[ "Piotr Nikolaievitch Rasputin", "Colossus" ],
		[ "Henry Heywood I", "Commander Steel" ],
		[ "James Sharp", "Coronary" ],
		[ "Jack Ryder", "Creeper" ],
		[ "Lee Travis", "Crimson Avenger" ],
		[ "Albert Elwood", "Crimson Avenger" ],
		[ "Vivian D'Aramis", "Crimson Fox" ],
		[ "Constance D'Aramis", "Crimson Fox" ],
		[ "Victor Stone", "Cyborg" ],
		[ "Scott Summers", "Cyclops" ],
		[ "Grant Emerson", "Damage" ],
		[ "Matthew Michael Murdock", "Daredevil" ],
		[ "Boston Brand", "Deadman" ],
		[ "Floyd Lawton", "Deadshot" ],
		[ "Slade Wilson", "Deathstroke, the Terminator" ],
		[ "Anthony Ludgate Druid", "Doctor Druid" ],
		[ "Kent Nelson", "Doctor Fate" ],
		[ "Eric and Linda Strauss", "Doctor Fate" ],
		[ "Inza Nelson", "Doctor Fate" ],
		[ "Hector Hall", "Doctor Fate" ],
		[ "Dr. Kimiyo Hoshi", "Doctor Light" ],
		[ "Dr. Beth Chapel", "Doctor Midnight" ],
		[ "Dr. Charles McNider", "Doctor Mid-Nite" ],
		[ "Dr. Pieter Cross", "Doctor Mid-Nite" ],
		[ "Nommo", "Doctor Mist" ],
		[ "Richard Occult", "Doctor Occult" ],
		[ "Martha Roberts", "Doll Girl" ],
		[ "Darrell Dane", "Doll Man" ],
		[ "DeMarr Davis", "Doorman" ],
		[ "Don Hall", "Dove" ],
		[ "Dawn Granger", "Dove" ],
		[ "Wolverman", "Dove" ],
		[ "Daniel Dunbar", "Dyna-Mite" ],
		[ "Rita Farr", "Elasti-Girl" ],
		[ "Ralph Dibny", "Elongated Man" ],
		[ "Emma Grace Frost", "Emma Frost" ],
		[ "Anita Fite", "Empress" ],
		[ "David Connor", "Eradicator" ],
		[ "Jason Blood", "Etrigan the Demon" ],
		[ "Jared Stevens", "Fate" ],
		[ "Beatriz DaCosta", "Fire" ],
		[ "Rod Reilly", "Firebrand" ],
		[ "Dannette Reilly", "Firebrand" ],
		[ "Alex Sanchez", "Firebrand" ],
		[ "Lorraine Reilly", "Firehawk" ],
		[ "Ronald Raymond", "Firestorm" ],
		[ "Professor Martin Stein", "Firestorm" ],
		[ "Jason Rusch", "Firestorm" ],
		[ "Bette Kane", "Flamebird" ],
		[ "Helena Kosmatos", "Fury" ],
		[ "Lyta Hall", "Fury" ],
		[ "Possibly Gabriel Rosetti", "Gabriel, Devil Hunter" ],
		[ "Joseph Jones", "General Glory" ],
		[ "Brion Markov", "Geo-Force" ],
		[ "Major Dan Stone", "Glob" ],
		[ "Mike Magnus", "Gold" ],
		[ "Charley Parker", "Golden Eagle" ],
		[ "Grace Choi", "Grace" ],
		[ "Oliver Queen, Connor Hawke", "Green Arrow" ],
		[ "Phillip Urich", "Green Goblin" ],
		[ "Alan Scott", "Green Lantern" ],
		[ "Hal Jordan", "Green Lantern" ],
		[ "Kyle Rayner", "Green Lantern" ],
		[ "John Stewart", "Green Lantern" ],
		[ "Guy Gardner", "Green Lantern" ],
		[ "Jim Harper", "Guardian" ],
		[ "Cindy Reynolds", "Gypsy" ],
		[ "Shiera Saunders", "Hawkgirl" ],
		[ "Kendra Sanders", "Hawkgirl" ],
		[ "Carter Hall", "Hawkman" ],
		[ "Tora Olafsdotter", "Ice" ],
		[ "Robert Louis Drake", "Iceman" ],
		[ "Bart Allen", "Impulse" ],
		[ "Robert Bruce Banner", "Incredible Hulk" ],
		[ "Kyle Rayner", "Ion" ],
		[ "Andrea Thomas", "Isis" ],
		[ "Jenny-Lynn Hayden", "Jade" ],
		[ "Jubilation Lee", "Jubilee" ],
		[ "Cain Marko", "Juggernaut" ],
		[ "Wally West", "Kid Flash" ],
		[ "Bart Allen", "Kid Flash" ],
		[ "Katherine Pryde", "Kitty Pryde" ],
		[ "Robert Kirkland Langstrom", "Man-Bat" ],
		[ "J'onn J'onzz", "Martian Manhunter" ],
		[ "Mary Batson", "Mary Marvel" ],
		[ "unknown", "Mathemaniac" ],
		[ "Rex Mason", "Metamorpho" ],
		[ "Barbara Morse Barton", "Mockingbird" ],
		[ "Reed Richards", "Mr. Fantastic" ],
		[ "Scott Free", "Mr. Miracle" ],
		[ "Terry Sloane", "Mr. Terrific" ],
		[ "Michael Holt", "Mr. Terrific" ],
		[ "James Arthur Madrox", "Multiple Man" ],
		[ "Jane St. Ives", "Nemesis" ],
		[ "Kurt Wagner", "Night Crawler" ],
		[ "Hannibal Hawkes", "Nighthawk" ],
		[ "Richard Grayson", "Nightwing" ],
		[ "Jason Todd", "Nightwing" ],
		[ "Hal Jordan", "Parallax" ],
		[ "William Robert Reilly", "Phat" ],
		[ "Patrick O'Brian", "Plastic Man" ],
		[ "Dr. Chen Lu", "Radioactive Man" ],
		[ "Edward Nygma", "Riddler" ],
		[ "Dick Grayson", "Robin" ],
		[ "Jason Todd", "Robin" ],
		[ "Tim Drake", "Robin" ],
		[ "Stephanie Brown", "Robin" ],
		[ "Carrie Kelley", "Robin" ],
		[ "Anna Marie", "Rogue" ],
		[ "Dr. Walter Langkowski", "Sasquatch" ],
		[ "Molly Fitzgerald", "Shamrock" ],
		[ "Steven Harmon", "Slapstick" ],
		[ "Melissa Joan Gold", "Songbird" ],
		[ "James Brendan Corrigan", "Spectre" ],
		[ "Hal Jordan", "Spectre" ],
		[ "Crispus Allen", "Spectre" ],
		[ "Robert Baldwin", "Speedball" ],
		[ "Peter Parker", "Spiderman" ],
		[ "William Nasland", "Spirit of '76" ],
		[ "Courtney Whitmore", "Stargirl" ],
		[ "Ted Knight", "Starman" ],
		[ "David Knight", "Starman" ],
		[ "Jack Knight", "Starman" ],
		[ "Henry Heywood I", "Steel" ],
		[ "Henry Heywood III", "Steel" ],
		[ "John Henry Irons", "Steel" ],
		[ "Natasha Irons", "Steel" ],
		[ "Clark Kent", "Superman" ],
		[ "Joshua Sanders", "Takion" ],
		[ "Albert Pratt", "The Atom" ],
		[ "Ramond Palmer", "The Atom" ],
		[ "Adam Cray", "The Atom" ],
		[ "Richard Henry Benson", "The Avenger (1930)" ],
		[ "Paul Cannon", "The Avenger (1980)" ],
		[ "Norbert Sykes", "The Badger" ],
		[ "Barry Eames", "The Bee" ],
		[ "Jay Garrick", "The Flash" ],
		[ "Barry Allen", "The Flash" ],
		[ "Wallace Rudolph West", "The Flash" ],
		[ "Charles Victor Szasz", "The Question" ],
		[ "Donna Troy", "Troia" ],
		[ "Harvey Dent", "Two-Face" ],
		[ "Troy Stewart", "Tyroc" ],
		[ "Edith Constance Sawyer", "U-Go-Girl" ],
		[ "Samantha Parrington", "Valkyrie" ],
		[ "Guy Gardner", "Warrior" ],
		[ "William Kaplan", "Wiccan" ],
		[ "Donna Troy", "Wonder Girl" ],
		[ "Cassie Sandsmark", "Wonder Girl" ],
		[ "Princess Diana", "Wonder Woman" ],
		[ "Queen Hippolyta", "Wonder Woman" ],
		
		[ "Bruce Parker", "Spiderbat?!" ],
		[ "Son of Odin", "Thor" ],
		[ "Reasons to live", 42 ]
		
	]
	
}