import { delay, now, isFunction } from "helper"

export default class Timer
{
    #interval
    #onTick = []
    
    #lastTick = null
    #running = false
    #forceTick = false
    
    constructor(interval)
    {
        this.#interval = Math.max(interval ?? 0, 10)
    }
    
    set onTick(value)
    {
        this.#onTick.add(value)
    }
    
    async start()
    {
        this.#running = true
        
        while (this.#running)
        {
            await this.#tick()
            await delay(83)
        }
    }
    
    async #tick()
    {
        if (!this.#forceTick && this.#lastTick != null)
        {
            const delta = now() - this.#lastTick
            if (delta < this.#interval)
                return
        }
        
        if (isFunction(this.#forceTick))
            this.#forceTick()
        
        this.#forceTick = null
        
        for (const onTick of this.#onTick)
            await onTick.call(this)
        
        this.#lastTick = now()
    }
    
    stop()
    {
        this.#running = false
    }
    
    forceTick(callback)
    {
        this.#forceTick = callback ?? true
    }
}