export default class Element
{
    #node
    
    constructor(node)
    {
        if (!(node instanceof Node))
            node = document.querySelector(node)
        
        this.#node = node
    }
    
    get node()
    {
        return this.#node
    }
}