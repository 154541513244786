import App from "app"
import Element from "element"

export default class MusicButton extends Element
{
    constructor()
    {
        super(".music-button")
        
        this.on("click", async () =>
        {
            const active = this.toggleClass("active")
            const musicBox = App.instance.musicBox
            await musicBox.show(active, 80)
        })
    }
}