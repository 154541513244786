import App from "app"

import { create, isUndefined, isNull, isBool, isString, isArray, isObject } from "helper"

export default class Result
{
    #node
    
    constructor(formula, type, value, options)
    {
        this.persistent = options?.persistent ?? false
        
        this.formula = formula
        
        if (isBool(type))
            this.type = type ? "success" : "failure"
        else
            this.type = type
        
        if (isString(value))
        {
            this.value = value
            return
        }
        
        if (isUndefined(value))
        {
            this.value = "undefined"
            return
        }
        
        if (isNull(value))
        {
            this.value = "null"
            return
        }
        
        if (value === NaN)
        {
            this.value = "0"
            return
        }
        
        if (isBool(value))
        {
            this.value = value ? "true" : "false"
            return
        }
        
        if (value.type)
        {
            this.value = value.toString()
            return
        }
        
        if (isArray(value) || isObject(value))
        {
            this.value = value.toJson()
            return
        }
        
        this.value = value.toString()
    }
    
    get node()
    {
        if (!this.#node)
            this.#node = create("div")
        
        this.#refresh()
        
        return this.#node
    }
    
    set node(value)
    {
        this.#node = value
        this.#refresh()
    }
    
    get isBlind()
    {
        if (this.type == "empty")
            return true
        
        if (this.type == "comment" && !this.value)
            return true
        
        if (this.type == "tracks" || this.type == "playlists")
        {
            if (!this.value)
                return true
        }
        
        return false
    }
    
    #refresh()
    {
        const node = this.#node
        
        node.className = "entry"
        node.addClass(this.type)
        
        let html
        
        switch (this.type)
        {
            case "error":
                node.html = `&nbsp;&nbsp;&nbsp;[Error] ${this.value}`
                break
                
            case "warning":
                node.html = `&nbsp;[Warning] ${this.value}`
                break
                
            case "empty":
                node.html = ""
                break
            
            case "comment":
                node.html = this.value
                break
                
            case "solution":
                node.html = `&nbsp;&nbsp;&nbsp;[Solve] ${this.formula.value} = ${this.value.humanize()}`
                break
                
            case "comperation":
                if (this.value == "true")
                    node.addClass("correct").html = `&nbsp;[Compare] ${this.formula.value} (Correct)`
                else
                    node.addClass("incorrect").html = `&nbsp;[Compare] ${this.formula.value} (Incorrect)`
                break
                
            case "success":
                node.html = `&nbsp;[Success] ${this.formula.value}`
                break
                
            case "failure":
                node.html = `&nbsp;[Failure] ${this.formula.value}`
                break
                
            case "info":
                node.html = `&nbsp;&nbsp;&nbsp;&nbsp;[Info] ${this.formula.value}`
                break
            
            case "emulator-game":
                node.clear()
                if (this.value == "undefined")
                    break
                
                const container = create("div")
                    .addClass("emulator-game-container")
                    .appendTo(node)
                create("div")
                    .addClass("emulator-game")
                    .appendTo(container)
                console.log(this.value)
                App.instance.emulator.test(this.value)
                break
            
            case "help":
                const lines = [
                    "playlist<i>s</i> <b>text</b>",
                    "create playlist",
                    "delete playlist <b>text</b>",
                    "track<i>s</i> <b>text</b>",
                    "add track <b>text</b>",
                    "remove track <b>text</b>",
                    "play <b>text</b>",
                ]
                html = `<ul>`
                for (const line of lines)
                    html += `<li>${line}</li>`
                html += `</ul>`
                node.html = html
                break
                
            case "tracks":
                const tracks = this.value.fromJson()
                html = `<ul>`
                for (const track of tracks)
                    html += `<li><div class="badget">${track.id}</div>${track.filename}</li>`
                html += `</ul>`
                node.html = html
                break
                
            case "playlists":
                const playlists = this.value.fromJson()
                html = `<ul>`
                for (const playlist of playlists)
                    html += `<li>${playlist.id}: ${playlist.name}</li>`
                html += `</ul>`
                node.html = html
                break
                
            default:
                node.html = "Unknown Result"
        }
        
        if (node.html.length == 0)
            node.html = "&nbsp;"
    }
}