import App from "app"
import Element from "element"
import Timer from "timer"

import { create, find, on, off, get, postForm } from "helper"

export default class MusicBox extends Element
{
    #playlist
    #timelineSlider
    #playButton
    #pauseButton
    
    #timer
    #editing = false
    
    constructor()
    {
        super(".music-box")
        
        this.#playlist = find("#playlist")
        
        this.#timelineSlider = find("#player-timeline-slider")
        
        this.#timelineSlider.on("mousedown", () =>
        {
            if (this.#editing)
                return
            
            this.#editing = true
            const mouseUp = () =>
            {
                off("mouseup", mouseUp)
                this.#forceAppTick()
            }
            on("mouseup", mouseUp)
        })
        
        this.#timelineSlider.on("change", async () =>
        {
            await postForm(`/api/player/track?${App.instance.queryString}`, {
                timeProgress: this.#timelineSlider.value / 1000
            })
        })
        
        this.#playButton = find("#player-play")
        this.#playButton.on("click", async () =>
        {
            this.#disableButtons()
            await get(`/api/player/resume?${App.instance.queryString}`)
            this.#forceAppTick()
        })
        
        this.#pauseButton = find("#player-pause")
        this.#pauseButton.on("click", async () =>
        {
            this.#disableButtons()
            await get(`/api/player/pause?${App.instance.queryString}`)
            this.#forceAppTick()
        })
        
        this.#timer = new Timer(200)
        this.#timer.onTick = () => this.#onTick()
    }
    
    start()
    {
        this.#timer.start()
    }
    
    refreshPlaylist()
    {
        const id = App.instance.playlistId
        const playlist = App.instance.getPlaylistById(id)
        
        this.#playlist.clear()
        
        for (const i in playlist.tracks)
        {
            const track = playlist.tracks[i]
            
            const div = create("div")
                .addClass("playlist-track")
                .setData("id", track.id)
            
            let index = i.toString();
            while (index.length < 3)
                index = `0${index}`
            
            create("div")
                .addClass("playlist-track-index")
                .setHtml(index)
                .appendTo(div)
            
            create("div")
                .addClass("playlist-track-title")
                .setHtml(track.getTitle())
                .appendTo(div)
            
            create("div")
                .addClass("playlist-track-artist")
                .setHtml(track.getArtist())
                .appendTo(div)
            
            div.parent = this.#playlist
        }
    }
    
    #disableButtons()
    {
        this.#playButton.disable()
        this.#pauseButton.disable()
    }
    
    #enableButtons()
    {
        this.#playButton.enable()
        this.#pauseButton.enable()
    }
    
    #forceAppTick()
    {
        App.instance.forceTick(() => this.#editing = false)
    }
    
    #onTick()
    {
        if (this.#editing)
            return
        
        const audioEngine = App.instance.audioEngine
        if (!audioEngine.ready)
            return
        
        this.#enableButtons()
        
        this.#playButton.show(audioEngine.paused)
        this.#pauseButton.show(!audioEngine.paused)
        
        this.#playlist.findAll(`.playlist-track`).forEach(x =>
        {
            x.setClass("playing", x.dataset.id == App.instance.activeTrackId)
        })
        
        this.#timelineSlider.value = Math.round(audioEngine.progress * 1000)
    }
}