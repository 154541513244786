import AudioEngine from "audio-engine"
import Commandline from "commandline"
import Content from "content"
import Formula from "formula"
import HeroList from "herolist"
import Emulator from "emulator"
import MusicBox from "music-box"
import MusicButton from "music-button"
import Playlist from "playlist"
import Result from "result"
import StartButton from "start-button"
import Timer from "timer"
import Track from "track"

import { find, on, get, post, clamp, delay, now, logChange, guid } from "helper"

export default class App
{
    static instance
    
    started = false
    
    startButton
    
    content = new Content
    commandline = new Commandline
    
    herolist = new HeroList
    
    emulator = new Emulator
    
    musicButton = new MusicButton
    musicBox = new MusicBox
    
    playlistId = 0
    #playlistId = 0
    
    audioEngine = new AudioEngine
    
    tracks = []
    
    #timer
    
    #uniqueIds = new Set
    
    #localStatus = {
        hash: 0,
        trackId: 0,
        paused: false,
        stopped: true,
        time: 0
    }
    
    constructor()
    {
        let hash = location.hash
        if (hash.startsWith("#"))
            hash = hash.substring(1)
        this.playlistId = parseInt(hash) || 1
    }
    
    async run()
    {
        this.startButton = new StartButton
        await this.startButton.start()
    }
    
    async start()
    {
        if (this.started)
            return
        
        this.started = true
        
        on("keydown", async e => await this.#onKeydown(e))
        
        await this.content.refresh()
        await this.#refreshCommandline()
        
        this.commandline.onInput = async () => this.#onCommandlineInput()
        this.commandline.onSubmit = async () => this.#onCommandlineSubmit()
        
        await this.commandline.activate()
        
        await find("#main").show(600)
        
        this.audioEngine.start()
        
        this.tracks = await Track.getAllFromApi()
        await this.updatePlaylists()
        
        this.#timer = new Timer(900)
        this.#timer.onTick = async () => await this.#onTick()
        this.#timer.start()
        
        this.musicBox.start()
    }
    
    get queryString()
    {
        return `playlist=${this.playlistId}`
    }
    
    get activeTrackId()
    {
        return this.#localStatus.trackId
    }
    
    async updatePlaylists()
    {
        this.playlists = await Playlist.getAllFromApi()
    }
    
    forceTick()
    {
        this.#timer.forceTick(...arguments)
    }
    
    async #onTick()
    {
        const url = `/api/player/status?${this.queryString}`
        const serverStatus = await get(url).then(x => x.json())
        if (!serverStatus)
            return
        
        let onHashChange = false
        let onPlaylistChange = false
        let onStatusChange = false
        
        if (this.#localStatus.hash != serverStatus.hash)
        {
            this.#logChange("      Hash ", this.#localStatus.hash, serverStatus.hash)
            onHashChange = true
        }
        
        if (this.#playlistId != this.playlistId)
        {
            this.#logChange("  Playlist ", this.#playlistId, this.playlistId)
            onPlaylistChange = true
        }
        
        if (this.#localStatus.trackId != serverStatus.trackId)
        {
            this.#logChange("  Track ID ", this.#localStatus.trackId, serverStatus.trackId)
            onStatusChange = true
        }
        
        if (this.#localStatus.paused != serverStatus.paused)
        {
            this.#logChange("    Paused ", this.#localStatus.paused, serverStatus.paused)
            onStatusChange = true
        }
        
        const stopped = serverStatus.stopped
        
        if (this.#localStatus.stopped != serverStatus.stopped)
        {
            this.#logChange("   Stopped ", this.#localStatus.stopped, stopped)
            onStatusChange = true
        }
        
        this.#localStatus.time = this.audioEngine.time
        
        const timeOffset = Math.abs(this.#localStatus.time - serverStatus.time)
        
        if (!stopped)
        {
            if (timeOffset > 3)
            {
                if (timeOffset > 6)
                {
                    this.#logChange("      Time ", this.#localStatus.time, serverStatus.time)
                    onStatusChange = true
                }
                else
                {
                    console.warn("Time Offset " + timeOffset)
                }
            }
        }
        
        if (onHashChange)
        {
            await this.#onHashChange(serverStatus)
        }
        
        if (onPlaylistChange)
        {
            await this.#onPlaylistChange(serverStatus)
        }
        
        if (onStatusChange)
        {
            await this.#onStatusChange(serverStatus)
        }
    }
    
    #logChange(name, from, to)
    {
        console.log(`[Change] ${name}: ${from} → ${to}`)
    }
    
    async #onHashChange(status)
    {
        if (this.#localStatus.hash != status.hash)
        {
            this.#localStatus.hash = status.hash
            await this.updatePlaylists()
            this.musicBox.refreshPlaylist()
        }
    }
    
    async #onPlaylistChange(status)
    {
        await this.#onHashChange(status)
        
        if (this.#playlistId != this.playlistId)
        {
            this.#playlistId = this.playlistId
            this.musicBox.refreshPlaylist()
        }
    }
    
    async #onStatusChange(status)
    {
        this.#onPlaylistChange(status)
        
        this.#localStatus = status
        
        const track = this.getTrackById(status.trackId)
        
        if (track && !status.stopped)
        {
            await this.audioEngine.play(track, {
                play: !status.paused,
                time: status.time
            })
            return
        }
        
        this.audioEngine.stop()
    }
    
    async #onKeydown(e)
    {
        if (!this.started)
        {
            this.startButton.style.color = "yellow"
            await this.start()
            return
        }
        
        if (this.content.entryForm.isVisible())
            return
        
        if (this.commandline.hasFocus())
            return
        
        if (e.key.ctrlKey)
            return
        
        if (!e.arrowUp && !e.arrowDown && !/^[0-9a-z]$/.test(e.key))
            return
        
        await this.commandline.activate()
        
        if (e.arrowUp || e.arrowDown)
        {
            const entries = this.content.entries
            
            let i = this.commandlineIndex
            i = clamp(i + (e.arrowUp ? -1 : 1), 0, entries.length - 1)
            this.commandlineIndex = i
            
            this.commandline.value = entries[i].expression
            await this.#refreshPreviewResult()
            
            return
        }
    }
    
    async #onCommandlineInput()
    {
        this.commandlineIndex = this.content.entries.length
        await this.#refreshPreviewResult()
    }
    
    async #onCommandlineSubmit()
    {
        this.commandline.deactivate()
            
        const formula = new Formula(this.commandline.value)
        
        let entry = await formula.toEntry()
        
        if (entry.result.persistent)
        {
            await post("/api/entries", entry)
                .then(x => x.status == 200 ? x.json() : null)
                .then(x => entry.id = x?.id ?? 0)
        }
        
        this.commandline.value = ""
        
        if (entry.id || !entry.result.isBlind)
        {
            this.content.entries.add(entry)
            this.content.addNode(entry)
        }
        
        await this.#refreshCommandline()
        
        await this.commandline.activate()
    }
    
    async #refreshPreviewResult()
    {
        await this.content.setPreviewResult(this.commandline.value)
        this.content.scrollDown()
    }
    
    async #refreshCommandline()
    {
        this.commandlineIndex = this.content.entries.length
        await this.#refreshPreviewResult()
    }
    
    getTracks(searchText)
    {
        if (searchText.trim().length == 0)
            return this.tracks
        
        const keywords = [ ...new Set(searchText.split(' ')) ]
        
        const result = []
        
        for (const track of this.tracks)
        {
            let found = true
            
            for (const keyword of keywords)
            {
                if (keyword.length == 0)
                    continue
                
                let _found = false
                
                if (track.id == keyword)
                {
                    _found = true
                }
                else
                {
                    for (const trackKeyword of track.keywords)
                    {
                        if (trackKeyword.includes(keyword.toLowerCase()))
                        {
                            _found = true
                            break
                        }
                    }
                }
                
                if (_found)
                    continue
                
                found = false
                break
            }
            
            if (found && !result.includes(track))
                result.add(track)
        }
        
        return result
    }
    
    getPlaylists(searchText)
    {
        if (searchText.trim().length == 0)
            return this.playlists
        
        const keywords = [ ...new Set(searchText.split(' ')) ]
        
        const result = []
        
        for (const playlist of this.playlists)
        {
            let notFound = false
            for (const keyword of keywords)
            {
                if (keyword.length == 0)
                    continue
                
                if (playlist.id != keyword && !playlist.name.toLowerCase().includes(keyword.toLowerCase()))
                {
                    notFound = true
                    break
                }
            }
            
            if (notFound)
                continue
            
            result.add(playlist)
        }
        
        return result
    }
    
    getTrackById(id)
    {
        for (const track of this.tracks)
            if (track.id == id)
                return track
        return null
    }
    
    getPlaylistById(id)
    {
        for (const playlist of this.playlists)
            if (playlist.id == id)
                return playlist
        return null
    }
    
    generateUniqueId()
    {
        let uniqueId
        while (true)
        {
            uniqueId = guid().substring(0, 8)
            if (!this.#uniqueIds.has(uniqueId))
            {
                this.#uniqueIds.add(uniqueId)
                break
            }
        }
        return uniqueId
    }
}