import Element from "element"

import { create, find } from "helper"

export default class Emulator extends Element
{
    #nodeId
    #script
    
    constructor()
    {
        super("#emulator")
    }
    
    test(nodeId)
    {
        if (this.#script)
        {
            this.#script.remove()
            this.#script = null
        }
        
        if (this.#nodeId)
        {
            find(`#${this.#nodeId}`).clear()
            this.#nodeId = null
        }
        
        this.#nodeId = nodeId
        
        const _ = window
        
        //_.EJS_core = "n64"
        //_.EJS_gameUrl = "static/roms/n64/Legend of Zelda, The - Ocarina of Time (USA).n64"
        _.EJS_core = "gba"
        _.EJS_gameUrl = "static/roms/gba/Sonic Advance (U) [!].zip"
        _.EJS_player = `#${this.#nodeId} .emulator-game`
        _.EJS_pathtodata = "static/emulatorjs/data/"
        _.EJS_startOnLoaded = true
        
        //_.EJS_onGameStart = () =>
        //{
        //    const x = find(`#${entry.nodeId} .emulator-game-container > div > div`)
        //    x.remove()
        //}
        
        //_.EJS_Settings = {
        //    volume: 1
        //}
        
        this.#script = create("script")
        this.#script.src = "static/emulatorjs/data/loader.js"
        this.#script.parent = document.body
    }
}