import { extend } from "helper"

extend([ Event.prototype ],
{
    enter:
    {
        get: function()
        {
            return this.keyCode == 13
        }
    },
    arrowUp:
    {
        get: function()
        {
            return this.keyCode == 38
        }
    },
    arrowDown:
    {
        get: function()
        {
            return this.keyCode == 40
        }
    }
})