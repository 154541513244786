import Element from "element"

import { extend } from "helper"

extend([ EventTarget.prototype, Element.prototype ],
{
    on: function(event, callback)
    {
        const original = this.original
        
        //if (arguments.length < 2)
        //    return
        //
        //original.listeners ??= { }
        //original.listeners[event] ??= [ ]
        //original.listeners[event].add(callback)
        
        original.addEventListener(...arguments)
        return this
    },
    off: function(event, callback)
    {
        const original = this.original
        
        //if (arguments.length < 2)
        //    return
        //
        //original.listeners ??= { }
        //original.listeners[event] ??= [ ]
        //original.listeners[event].remove(callback)
        
        original.removeEventListener(...arguments)
        return this
    }
})