export default class AudioEngine
{
    #audio = new Audio
    #src = ""
    
    #context
    //#gainNode
    
    start()
    {
        this.#context = new AudioContext
        
        //this.#gainNode = this.#context.createGain()
        //this.#gainNode.gain.value = .2
        //this.#gainNode.connect(this.#context.destination)
        
        this.ready = true
    }
    
    stop()
    {
        this.src = ""
    }
    
    get time()
    {
        return this.#audio.currentTime
    }
    
    get duration()
    {
        const duration = this.#audio.duration
        return isNaN(duration) ? 0 : duration
    }
    
    get progress()
    {
        return this.duration ? this.time / this.duration : 0
    }
    
    get paused()
    {
        return !this.duration || this.#audio.paused
    }
    
    set src(value)
    {
        if (this.#src == value)
            return
        
        this.#src = value
        this.#audio.src = value
        
        if (this.#src)
            this.#audio.load()
        
    }
    
    async play(track, options)
    {
        if (!this.ready)
            return
        
        options ??= { }
        
        if (!options.play)
            this.#audio.pause()
        
        this.src = track.url
        
        if (options.time)
            this.#audio.currentTime = options.time
        
        if (options.play)
            this.#audio.play()
    }
    
    //const stream = await fetch(url)
    //const buffer = await stream.arrayBuffer()
    //this.#buffer = this.#context.createBufferSource()
    //source.buffer = await this.#context.decodeAudioData(buffer)
    //source.start(delayS)
}