import Content from "content"
import Element from "element"
import Formula from "formula"

import { find, put, del } from "helper"

export default class EntryForm extends Element
{
    #onDelete = []
    
    constructor()
    {
        super("#entry-form")
        
        const expression = find("#entry-form-expression")
        const resultValue = find("#entry-form-result-value")
        
        expression.on("input", async () =>
        {
            Formula.applyMathSymbols(expression)
            const formula = new Formula(expression.value)
            const result = await formula.toResult()
            resultValue.value = result.value
        })
        
        find("#entry-form-save").on("click", async () =>
        {
            const node = this.entry.result.node
            const formula = new Formula(expression.value)
            this.entry.expression = formula.value
            this.entry.result = await formula.toResult({ entry: this.entry })
            this.entry.result.node = node
            
            this.#updateValue()
            
            await put(`/api/entries`, this.entry)
            
            await this.close()
        })
        
        find("#entry-form-cancel").on("click", async () =>
        {
            await this.close()
        })
        
        find("#entry-form-delete").on("click", async () =>
        {
            this.#onDelete.forEach(x => x.call(this, this.entry))
            
            this.entry.result.node.parent = null
            
            await del(`/api/entries/${this.entry.id}`)
            
            await this.close()
        })
    }
    
    set onDelete(value)
    {
        this.#onDelete.add(value)
    }
    
    async open(entry)
    {
        this.entry = entry
        
        find("#entry-form-id").html = this.entry.id
        find("#entry-form-expression").value = this.entry.expression
        
        this.#updateValue()
        
        await this.show(200)
    }
    
    #updateValue()
    {
        find("#entry-form-result-value").value = this.entry.result.value
    }
    
    async close()
    {
        delete this.entry
        await super.hide(200)
    }
}