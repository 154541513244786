import { get } from "helper"

export default class Track
{
    id = 0
    path
    filename
    artist
    album
    title
    keywords = []
    
    static async getAllFromApi()
    {
        const tracks = await get(`/api/tracks`).then(x => x.json())
        
        return this.fromArray(tracks)
    }
    
    static async getFromApi(id)
    {
        const track = await get(`/api/tracks/${id}`).then(x => x.json())
        
        return this.fromObject(track)
    }
    
    static fromArray(tracks)
    {
        const _items = []
        for (const track of tracks)
            _items.add(this.fromObject(track))
        return _items
    }
    
    static fromObject(item)
    {
        const _item = new Track
        Object.assign(_item, item)
        return _item
    }
    
    getArtist()
    {
        if (this.artist == "Various")
            return ""
        return this.artist
    }
    
    getTitle()
    {
        if (!this.title || this.title == "Sampler" || this.title == "Untitled")
            return this.filename
        return this.title
    }
}